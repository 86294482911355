<style lang="less" scoped>
.icon_image {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
  transition: all 1s linear 0s;
  img{
	  object-fit: cover;
	  transition: all 1s linear 0s;
  }
  .text {
  }
  .Obscuration {
    width: 100%;
    height: 100%;
    position: absolute;
    visibility: hidden;
    transition: all 1s linear 0s;
    pointer-events: none;
  }
}
.icon_image:hover {
  overflow: hidden;
  img {
    transform: scale(1.2);
    transition: all 1s linear 0s;
  }
  .text {
    visibility: hidden;
  }
  .Obscuration {
    transition: all 1s linear 0s;
    background: rgba(0, 0, 0, 0.3);
    visibility: visible;
  }
}
.content{
	width: 100%;
	height: 100%;
}
</style>
<template>
  <div class="content">
    <div class="icon_image">
      <el-image class="img icon-image" :src="image" :preview-src-list="[image]" v-if="larger"></el-image>
      <img class="img" :src="image" v-else>
      <div class="text" v-if="titleShow">
        <slot name="title"></slot>
      </div>
      <div class="Obscuration" v-if="obscuration">
        <!-- <div>查看大图</div> -->
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
props:{
    image:[String],
    text:[String],
    obscuration:{
      type:[Boolean],
      default:false
    },
    titleShow:{
      type:[Boolean],
      default:false
    },
    larger:{
      type:[Boolean],
      default:false
    }
}
}
</script>