<style lang="less" scoped>

	.content {
		background-color: #ffffff;
		padding-bottom:20px;
		.el-cascader-menu__wrap{
			height: 0;
		}
		/deep/.icon_image img{
			object-fit: contain !important;
		}
		.head {
			background-repeat: no-repeat;
			background-size: cover;
			height: 560px;
			text-align: left;
			padding: 352px 0 0 0;

			.title {
				width: 1361px;
				margin: 0 auto;
				font-size: 58px;
				color: #ffffff;
				text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
			}

			.txt {
				width: 1361px;
				margin: 0 auto;
				font-size: 35px;
				padding-top: 20px;
				color: #ffffff;
				text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
			}
		}

		.box {
			width: 1362px;
			margin: 0 auto;
			padding-top: 80px;
			
			.onlineDisplay_option {
				padding: 40px;
				
			}
			.onlineDisplay_box {
				flex-wrap: wrap;
				justify-content: flex-start;

				.onlineDisplay_image {
					width: 33.33%;
					max-width: 33.33%;
					min-width: 33.33%;
					padding-bottom: 20px;
					cursor: pointer;
					.image {
						width: 440px;
						height: 312px;
						color: #FFFFFF;
						.type {
							font-size: 32px;
							margin-top: 58px;
						}

						.time {
							font-size: 32px;
						}

						.track {
							font-size: 18px;
							padding-bottom: 20px;
							margin: 0 auto;
						}
						.border{
							border-bottom: 3px solid #FFFFFF;
							width: 59px;
							margin: 0 auto;
						}
						.name {
							padding-top: 20px;
							font-size: 18px;
							width: 90%;
							margin: 0 auto;
						}
						.author {
							margin-top: 20px;
							font-size: 20px;
						}
					}
				}
			}
		}

		.notes {
			height: 58px;
			line-height: 58px;
			background: #f5f5f5;
			font-size: 18px;
			color: #666666;
		}
	}

	.width242 {
		width: 242px;
		height: 58px;
		margin-right: 21px;
		
	}
	
	/deep/ .el-input__inner {
		height: 58px;
	}
</style>
<style lang="less">
	.onlineDisplay_option{
		/deep/.el-cascader-menu__wrap{
			height: auto;
		}
	}
	
</style>
<template>
	<div class="content online_conten">
		<div class="head" v-if="image" :style="{background: 'url('+ image +')'}" style="background-repeat: no-repeat;background-size: cover;">
			<div class="title">{{$t('onlineDisplay.title')}}</div>
			<div class="txt">{{$t('onlineDisplay.text')}}</div>
		</div>
		<div class="box">
			<div class="notes">{{$t('onlineDisplay.notes')}}</div>
			<div class="onlineDisplay_big_box">
				<div class="onlineDisplay_option">
					<!-- <el-date-picker value-format="yyyy" v-model="form.date" type="year" class="width242"
						:placeholder="$t('onlineDisplay.head1')" @change="onlineDisplayList"></el-date-picker> -->
					<!-- <el-select v-model="form.theme_id" :placeholder="$t('onlineDisplay.head2')" class="width242"
						@change="awardsList">
						<el-option v-for="item in options2" :key="item.id" :label="item.en_match_name" :value="item.id">
						</el-option>
					</el-select> -->
					<el-select :no-data-text="$t('newText.msg133')" v-model="form.year" :placeholder="$t('onlineDisplay.head1')" class="width242"
						@change="onlineDisplayLists">
						<el-option v-for="item in yearArr" :key="item.year" :label="item.year" :value="item.year">
						</el-option>
					</el-select>
					<el-cascader  :options="options2"  :show-all-levels="false" class="width242" v-model="form.theme_id" :placeholder="$t('onlineDisplay.head2')" :props="{value:'id',label:'en_channel_name',children:'theme'}" @change="awardsList(form.theme_id)">
						
					</el-cascader>
					<el-select :no-data-text="$t('newText.msg133')" v-model="form.awards_id" :placeholder="$t('onlineDisplay.head3')" class="width242"
						@change="onlineDisplayList">
						<el-option v-for="item in options3" :key="item.id" :label="item.en_reward_name"
							:value="item.id">
						</el-option>
					</el-select>
					<el-select :no-data-text="$t('newText.msg133')" v-model="form.en_check_state" :placeholder="$t('onlineDisplay.head4')" class="width242"
						@change="onlineDisplayList">
						<el-option v-for="item in options4" :key="item.state" :label="item.detail" :value="item.state">
						</el-option>
					</el-select>
				</div>
				<div class="onlineDisplay_box flex_disp">
					<div class="onlineDisplay_image" v-for="(item,index) in contentList" :key="index"
						@click="toUrl(item.id)">
						<div class="image">
							<image-Obscuration :image="item.en_img" :obscuration="true" :titleShow="true"
								:larger="false">
								<template v-slot:title>
									<div
										style="position: absolute;bottom: 0;right: 0;font-size: 1.43229vw;color: #f9f7f7;padding: 0.78125vw;">
										<span>{{item.en_name}}</span>
									</div>
								</template>
								<template v-slot:content>
									<div class="type">{{item.awards.en_reward_name}}</div>
									<div class="time">{{item.en_year }}</div>
									<div class="track">{{item.en_track}}</div>
									<div class="border"></div>
									<!-- <el-divider></el-divider> -->
									<div class="name text_1_size">{{item.en_describe}}</div>
									<div class="author">{{item.author}}</div>
								</template>
							</image-Obscuration>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import imageObscuration from '../../../components/imageObscuration'
	// import $ from 'jquery';
	
	export default {
		components: {
			imageObscuration
		},
		data() {
			return {
				options2: [],
				options3: [],
				options4: [],
				form: {
					date: "",
					theme_id: "",
					awards_id: "",
					en_check_state: "",
					year:''
				},
				contentList: [],
				image: "",
				yearArr:[]
			}
		},
		mounted() {
			this.onlineDisplayList()
			
			this.exhibitionList()
			this.imageList();
			this.getYear();
		},
		methods: {
			async imageList() {
				try {
					let _this = this
					let params = {
						position_id: 2
					}
					let res = await this.$axios.get('ads/getList', {
						params: params
					});
					if (res.code == 1) {
						this.image = 'https://cultural-creation.oss-cn-beijing.aliyuncs.com/' + res.data[0].image
					}
				} catch (error) {
					console.log(error);
				}
			},
			toUrl(id) {
				this.$router.push({
					path: 'onlineDisplayDetails',
					query: {
						id: id
					}
				})
			},
			async exhibitionList() {
				try {
					let _this = this
					let params = {}
					let res = await this.$axios.get('entries/check_state', {
						theme_id: this.form.theme_id
					});
					if (res.code == 1) {
						this.options4 = res.data
					}
				} catch (error) {
					console.log(error);
				}
			},
			async getYear(){
				try{
					let res = await this.$axios.post('match/yearList');
					if(res.code==1){
						this.yearArr=res.data;
					}
				}catch(e){
					//TODO handle the exception
				}
			},	
			async awardsList(id) {
				console.log(id)
				try {
					let _this = this
					let params = {
						theme_id: id[1],
					}
					let res = await this.$axios.get('match/awardsList', {
						params: params
					});
					if (res.code == 1) {
						this.options3 = res.data
						this.onlineDisplayList()
					}
				} catch (error) {
					console.log(error);
				}
			},
			async matchList() {
				try {
					let _this = this
					let params = {
						page: 1,
						year: this.form.year
					}
					let res = await this.$axios.get('match/index', {
						params: params
					});
					if (res.code == 1) {
						this.options2 = res.data[0].channel
						
						this.options2.forEach((item,index)=>{
							item.theme.forEach((ele,i)=>{
								this.$set(ele,'en_channel_name',ele.en_theme)
							})
						})
						console.log(this.options2)
					}
				} catch (error) {
					console.log(error);
				}
			},
			onlineDisplayLists(){
				this.onlineDisplayList()
				this.matchList()
			},
			async onlineDisplayList() {
				try {
					
					let _this = this
					let params = {
						page: 1,
						limit: 20,
						theme_id: this.form.theme_id[1],
						awards_id: this.form.awards_id,
						en_check_state: this.form.en_check_state,
						date: this.form.year
					}
					let res = await this.$axios.get('entries/exhibition', {
						params: params
					});
					if (res.code == 1) {
						this.contentList = res.data.list
						this.contentList.forEach((item,index)=>{
							item.en_img = item.en_img.split(',')[0]
						})
						// this.$router.push('personalCenter')
					}
				} catch (error) {
					console.log(error);
				}
			},
		}

	}
</script>
